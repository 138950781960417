export const projectStatusLabels = {
	demo: 'Demo',
	draft: 'Draft',
	published: 'Published',
	unpublished: 'Unpublished',

	// reserved
	'_blank1': '',
	'_blank2': '',
}

export const sessionStatusLabels = {
	completed: 'Completed',
	abandoned: 'Abandoned',
	active: 'Active'
}

export const toastTitles = {
	'participant-included': 'Participant included',
	'participant-excluded': 'Participant excluded',
	'copied-to-clipboard': 'Copied to clipboard',
	'duplicated-project': 'Duplicated project',
}

export const toastTypes = {
	'participant-included': 'success',
	'participant-excluded': 'success',
	'copied-to-clipboard': 'success',
	'duplicated-project': 'success',
}