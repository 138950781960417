export function CheckmarkFilled({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g clipPath='url(#clip0_532_12971)'>
				<path
					d='M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2V2ZM14 21.5908L9.00001 16.5908L10.5906 15L14 18.4092L21.41 11L23.0057 12.5859L14 21.5908Z'
					style={{
						fill: 'currentColor',
						fillOpacity: 1
					}}
				/>
			</g>
			<defs>
				<clipPath id='clip0_532_12971'>
					<rect
						width='32'
						height='32'
						fill='white'
						style={{
							fill: 'currentColor',
							fillOpacity: 1
						}}
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export function CheckmarkOutline({
	className = 'text-icon-primary'
}: {
	className?: string
}) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path
				d='M14 21.414L9 16.413L10.413 15L14 18.586L21.585 11L23 12.415L14 21.414Z'
				style={{
					fill: 'currentColor',
					fillOpacity: 1
				}}
			/>
			<path
				d='M16 2C13.2311 2 10.5243 2.82109 8.22201 4.35943C5.91973 5.89777 4.12531 8.08427 3.06569 10.6424C2.00606 13.2006 1.72881 16.0155 2.269 18.7313C2.8092 21.447 4.14257 23.9416 6.1005 25.8995C8.05844 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 28C13.6266 28 11.3065 27.2962 9.33316 25.9776C7.35977 24.6591 5.8217 22.7849 4.91344 20.5922C4.00519 18.3995 3.76755 15.9867 4.23058 13.6589C4.6936 11.3311 5.83649 9.19295 7.51472 7.51472C9.19295 5.83649 11.3311 4.6936 13.6589 4.23058C15.9867 3.76755 18.3995 4.00519 20.5922 4.91345C22.7849 5.8217 24.6591 7.35977 25.9776 9.33316C27.2962 11.3065 28 13.6266 28 16C28 19.1826 26.7357 22.2348 24.4853 24.4853C22.2348 26.7357 19.1826 28 16 28Z'
				style={{
					fill: 'currentColor',
					fillOpacity: 1
				}}
			/>
		</svg>
	)
}
