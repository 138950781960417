import clsx from 'clsx'
import { type Renderable, Toaster, resolveValue } from 'react-hot-toast'
import { toastTitles, toastTypes } from '~/constants/labels'
import { CheckmarkFilled } from '~/icons/checkmarks'
import { WarningFilled } from '~/icons/warnings'

export type NotificationStyle = 'info' | 'error' | 'success'

export type NotificationProps = {
	title: string
	$style: NotificationStyle
	visible?: boolean
	message?: string
	renderableMessage?: Renderable
	className?: string
	noAnimate?: boolean
	inline?: boolean
}

export default function Notification({
	title,
	$style,
	visible,
	message,
	renderableMessage,
	className,
	noAnimate,
	inline
}: NotificationProps) {
	const notificationStyle = clsx(
		'flex relative py-4 pr-12 bg-notification-secondary-background',
		$style === 'info' &&
		'border-l-2 border-l-support-info bg-notification-primary-background',
		$style === 'success' && 'border-l-2 border-l-support-success',
		$style === 'error' && 'border-l-2 border-l-support-error',
		'transition-opacity',
		className
	)

	const icons = {
		error: <WarningFilled />,
		success: <CheckmarkFilled className='text-support-success' />
	}

	const icon = icons[$style]

	return (
		<div
			key={title}
			className={notificationStyle}
			style={{
				opacity: visible ? 1 : 0,
				animation: !noAnimate ? 'animate-toast-enter 0.125s ease' : ''
			}}
		>
			<div className='px-4'>{icon}</div>
			<div className={clsx(inline && 'flex gap-2')}>
				<p className='text-body font-semibold'>{title}</p>
				<p className='text-body'>{renderableMessage ?? message}</p>
			</div>
		</div>
	)
}

export function Toast() {
	const toastStyle = clsx('relative top-12', 'transition-opacity')

	const notificationStyle = clsx('w-72', 'transition-opacity')

	return (
		<Toaster
			position='top-right'
			reverseOrder={true}
			gutter={16}
			containerClassName=''
			containerStyle={{ top: '64px', right: '16px' }}
			toastOptions={{
				className: toastStyle,
				duration: 3750,
				style: {
					background: '#363636',
					color: '#fff'
				},

				// Default options for specific types
				success: {
					duration: 3000,
					theme: {
						primary: 'green',
						secondary: 'black'
					}
				}
			}}
		>
			{t => (
				<Notification
					title={toastTitles[t.id]}
					$style={toastTypes[t.id]}
					visible={t.visible}
					renderableMessage={resolveValue(t.message, t)}
					className={notificationStyle}
				/>
			)}
		</Toaster>
	)
}
