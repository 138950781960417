import { useFetcher } from '@remix-run/react'

export function isFetching(
    fetcher: ReturnType<typeof useFetcher>,
    matchPredicate: (formData: FormData) => boolean
) {
    return (
        (fetcher.state === 'loading' || fetcher.state === 'submitting') &&
        fetcher.formData &&
        matchPredicate(fetcher.formData)
    )
}
