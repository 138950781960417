import { text } from 'd3'
import { z } from 'zod'

export const messages = {
	default: 'Invalid input',
	name_required: 'Your name is required',
	email_required: 'Your email address is required',
	email_invalid: 'Enter a valid email address',
	password_required: 'Your password is required',
	password_mismatch: 'Passwords do not match',
	password_too_small: 'Password must be at least 8 characters long',
	password_at_least_one_lowercase:
		'Password must contain at least one lowercase letter',
	password_at_least_one_uppercase:
		'Password must contain at least one uppercase letter',
	password_at_least_one_number: 'Password must contain at least one number',
	password_at_least_one_special_character:
		'Password must contain at least one special character',
	terms_not_accepted: 'You must agree to the Terms of service',
	not_yet_implemented: 'Not yet implemented',
	same_password: 'Password cannot be the same as the current password'
}

export const passwordStrengthMessages = {
	password_too_small: 'At least 8 characters',
	password_at_least_one_uppercase: 'One uppercase character',
	password_at_least_one_lowercase: 'One lowercase character',
	password_at_least_one_number: 'One number',
	password_at_least_one_special_character: 'One special character'
}

// This is also the order they should appear in
export const passwordStrengthAspects = [
	'password_too_small',
	'password_at_least_one_uppercase',
	'password_at_least_one_lowercase',
	'password_at_least_one_number',
	'password_at_least_one_special_character'
]

//
// OBJECTS

export const name = z.string().min(1, {
	message: 'name_required'
})

export const toggle = z.boolean()

export const email = z
	.string()
	.min(1, {
		message: 'email_required'
	})
	.email({
		message: 'email_invalid'
	})

export const password = z.string().min(1, {
	message: 'password_required'
})

export const passwordStrength = z
	.string()
	.min(8, {
		message: 'password_too_small'
	})
	.regex(/[a-z]/, 'password_at_least_one_lowercase')
	.regex(/[A-Z]/, 'password_at_least_one_uppercase')
	.regex(/[0-9]/, 'password_at_least_one_number')
	.regex(/[^a-zA-Z0-9]/, 'password_at_least_one_special_character')

export const termsAccepted = z
	.boolean()
	.refine(value => { console.log('refine', value); return value === true }, {
		message: 'terms_not_accepted'
	})

// This is more of a special case, consider moving
export const currentPassword = z.string().superRefine((value, ctx) => {
	return {
		message: 'not_yet_implemented'
	}
})

// re-entered password refinement happens at the form level
export const confirmPassword = z.string()

//
// REFINEMENTS

const passwordsMatch = [
	form => form.password === form.confirmPassword,
	{
		message: 'password_mismatch',
		path: ['confirmPassword']
	}
]

//
// FORMS

export const loginForm = z.object({
	email,
	password
})

export const signupForm = z.object({
	name,
	email,
	password: passwordStrength,
	termsAccepted
})

export const profileForm = z.object({
	name,
	// email
})

export const forgotPasswordForm = z.object({
	email
})

export const waitlistForm = z.object({
	email
})

// name isn't the correct field name
export const feedbackForm = z.object({
	comments: name
})

// Reset password is intended to be used from the email link,
// because the user has indicated they do not know their password
export const resetPasswordForm = z
	.object({
		password: passwordStrength,
		confirmPassword
	})
	.refine.apply(null, passwordsMatch)

// Change password is intended to be used from the account settings page,
// because this user should know their password.
export const changePasswordForm = z
	.object({
		currentPassword,
		password,
		confirmPassword
	})
	.refine.apply(null, passwordsMatch)

export const minimumTwoItems = z.array(z.string()).min(2, {
	message: 'fewer-than-two-items:fewer-than-two-items'
})

export const minimumTwoContainers = z.array(z.string()).min(2, {
	message: 'fewer-than-two-containers:fewer-than-two-containers'
})

/*
	We are giving zod an array of entries
	eg. [ [id, name], [id, name] ]
*/
export const duplicateItem = z.array(z.array(z.string())).superRefine((names, ctx) => {
	for (const [id, name] of names) {
		if (names.filter(n => n[1] === name).length > 1) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `${id}:duplicate-item`
			})
		}
	}
})

export const duplicateContainer = z
	.array(z.array(z.string()))
	.superRefine((names, ctx) => {
		for (const [id, name] of names) {
			if (names.filter(n => n[1] === name).length > 1) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `${id}:duplicate-container`
				})
			}
		}
	})

export const noCardsSorted = (stackLength: number) =>
	z.array(z.string()).max(stackLength - 1, {
		message: 'no-cards-sorted:no-cards-sorted'
	})